import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "contentSwitch",
    "contentSection",
    "contentSubSection",
    "turboFrame",
    "turboButtonWrapper",
  ];

  connect() {
    this.updateContent();
    this.addEventListeners();
  }

  addEventListeners() {
    ["turbo:load", "turbo:frame-load"].forEach((eventType) =>
      document.addEventListener(eventType, this.updateContent.bind(this))
    );

    document.addEventListener(
      "content_filter:changed",
      this.switchContentOnFilterChange.bind(this)
    );
  }

  switchContent(event) {
    event.preventDefault();
    this.updateUrlParams(event.currentTarget.dataset.paramKey, event.currentTarget.id);
    this.setUrlParamsOnForm();
    this.updateContent();
    this.fireFilterEvent();
  }

  switchContentOnFilterChange(event) {
    const { checkedRadio } = event.detail;
    const contentSwitch = this.getContentSwitchByContentType(checkedRadio);

    if (contentSwitch) {
      this.updateUrlParams(contentSwitch.dataset.paramKey, checkedRadio);
    }

    this.setUrlParamsOnForm();
    this.updateContent();
  }

  getContentSwitchByContentType(contentType) {
    return this.contentSwitchTargets.find((contentSwitch) => {
      return Array.from(contentSwitch.children).some(
        (li) => li.dataset.contentType === contentType
      );
    });
  }

  updateUrlParams(paramKey, value) {
    const url = new URL(window.location);

    if (value == paramKey) {
      url.searchParams.set(paramKey, "true");
    } else {
      url.searchParams.delete(paramKey);
    }

    window.history.pushState({}, "", url);
  }

  updateContent() {
    const urlParams = new URLSearchParams(window.location.search);

    this.contentSectionTargets.forEach((section) => {
      this.updateSection(section, urlParams);
    });

    this.contentSubSectionTargets.forEach((section) => {
      this.updateSection(section, urlParams);
    });
  }

  updateSection(section, urlParams) {
    const param = urlParams.get(section.dataset.paramKey) === "true";

    this.toggleVisibility(section, section.dataset.paramKey, param);
    this.updateStyles(section.dataset.paramKey, param);
  }

  toggleVisibility(element, param, paramState) {
    if (element.dataset.contentType == param) {
      paramState ? element.classList.remove("hidden") : element.classList.add("hidden");
    } else {
      paramState ? element.classList.add("hidden") : element.classList.remove("hidden");
    }
  }

  updateStyles(param, paramState) {
    const element = this.contentSwitchTargets.find((contentSwitch) => {
      return contentSwitch.dataset.paramKey == param;
    });

    const styleClasses = [
      "bg-white",
      "border-0",
      "sm:border-b-2",
      "sm:border-forest-600",
      "hover:bg-white",
      "active",
    ];

    element.querySelectorAll("li").forEach((li) => {
      if (li.dataset.paramKey == li.dataset.contentType) {
        if (paramState) {
          li.classList.add(...styleClasses);
        } else {
          li.classList.remove(...styleClasses);
        }
      } else {
        if (paramState) {
          li.classList.remove(...styleClasses);
        } else {
          li.classList.add(...styleClasses);
        }
      }
    });
  }

  setUrlParamsOnForm() {
    this.turboButtonWrapperTargets.forEach((wrapper) => {
      const form = wrapper.querySelector("form");

      if (form) {
        const originalAction = form.getAttribute("action").split("?")[0];
        const params = new URLSearchParams(window.location.search).toString();

        const formattedParams = params ? `?${params}` : "";
        form.setAttribute("action", `${originalAction}${formattedParams}`);
      }
    });
  }

  fireFilterEvent() {
    this.element.dispatchEvent(
      new CustomEvent("content_switch:changed", {
        bubbles: true,
      })
    );
  }
}
