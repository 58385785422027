import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["filterItemsWrapper"];
  connect() {
    document.addEventListener("content_filter:changed", this.handleContentFilterChange.bind(this));
  }

  handleContentFilterChange(event) {
    const { checkedRadio, checkedCheckboxes } = event.detail;

    if (this.filterItemsWrapperTarget.dataset.contentId == checkedRadio) {
      this.updateTaskList(checkedRadio, checkedCheckboxes);
    }
  }

  updateTaskList(_checkedRadio, checkedCheckboxes) {
    const filterItems = [...this.filterItemsWrapperTarget.children];

    filterItems.forEach((filterItem) => {
      if (checkedCheckboxes.length > 0) {
        filterItem.classList.add("hidden");
      } else {
        filterItem.classList.remove("hidden");
      }
      const filterItemId = filterItem.dataset.id;
      if (checkedCheckboxes.includes(filterItemId)) {
        filterItem.classList.remove("hidden");
      }
    });
  }
}
