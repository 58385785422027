import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  openAndDownload(event) {
    const fileUrl = event.currentTarget.dataset.actionLink;

    if (fileUrl) {
      this.downloadFile(fileUrl);
    }
  }

  downloadFile(url) {
    const link = document.createElement("a");
    link.href = url;
    link.download = true;
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
