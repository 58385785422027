import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["radio", "checkboxGroup"];

  connect() {
    this.initializeFilter();

    document.addEventListener("content_switch:changed", this.initializeFilter.bind(this));
  }

  initializeFilter() {
    const urlParams = new URLSearchParams(window.location.search);
    const filterFromUrlParam = urlParams.has("category") ? "category" : "priority";
    this.updateActiveFilter(filterFromUrlParam);
  }

  updateActiveFilter(filter) {
    this.radioTargets.forEach((radio) => {
      if (radio.value === filter) radio.checked = true;
    });
    this.toggleCheckboxes(filter);
  }

  updateFrame(event) {
    this.toggleCheckboxes(event.currentTarget.value);
    this.fireFilterEvent();
  }

  toggleCheckboxes(filter) {
    this.checkboxGroupTargets.forEach((checkboxGroup) => {
      checkboxGroup.classList.toggle("hidden", checkboxGroup.dataset.filterTarget !== filter);
    });
  }

  fireFilterEvent() {
    const checkedRadio = this.radioTargets.find((radio) => radio.checked).value;
    const checkedCheckboxes = this.checkboxGroupTargets
      .find((checkboxGroup) => checkboxGroup.dataset.filterTarget === checkedRadio)
      .querySelectorAll("input[type=checkbox]:checked");

    this.element.dispatchEvent(
      new CustomEvent("content_filter:changed", {
        bubbles: true,
        detail: {
          checkedRadio: checkedRadio,
          checkedCheckboxes: Array.from(checkedCheckboxes).map((checkbox) => checkbox.value),
        },
      })
    );
  }
}
